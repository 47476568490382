<template>
  <div class="invoicing">
    <div class="buttons is-pulled-right">
<!--      <div class=" field ">-->
<!--        <div class="control select ">-->
<!--          <select name="type" id="invoice_type">-->
<!--            <option value="invoice">Invoice</option>-->
<!--            <option value="credit_note">Credit Note</option>-->
<!--          </select>-->
<!--        </div>-->
<!--      </div>-->

    </div>
    <h3 class="">
      <span class="title">
        {{ t('New invoice') }} <small v-if="!loaders.customer">(<router-link :to="`/customer/${$route.params.parent_identifier}/${$route.params.company_id}`">{{ customer.company_name }}</router-link>)</small>
          <span v-if="loaders.customer" class='fa fa-spin fa-spinner'></span>
      </span>
    </h3>
    <div class="is-clearfix"></div>
    <div class="columns">
      <div class="column is-12 ">
        <div class="box">
          <div class="columns">

            <div class="column is-3 pt-0">

              <div class="field">
                <label class="has-text-weight-bold">Next Invoice ID:</label>
                <div class="control" :class=" {
                  'has-icons-right' : loaders.invoice
                }">
                  <input type="text" :disabled="loaders.creating || loaders.invoice" class='input mt-2' name='invoice' v-model="invoice.invoice" placeholder="Invoice Id">
                  <span class="icon is-right  mt-2" v-if="loaders.invoice">
                    <span class="fas fa-spin fa-spinner"></span>
                  </span>
                </div>
              </div>

            </div>

            <div class="column is-3 pt-0">

              <div class="field">
                <label class="has-text-weight-bold">Issued at:</label>
                <div class="control">
                  <input type='date' :disabled="loaders.creating" class='input mt-2' name='issued_at' v-model="invoice.issued_at">
                </div>
              </div>

            </div>

            <div class="column is-2 pt-0">

              <div class="field">
                <label class="has-text-weight-bold">Payment Terms:</label>
                <div class="control">
                  <input type='number' :disabled="loaders.creating" class='input mt-2' name='payment_days' v-model="invoice.payment_terms">
                </div>
              </div>

            </div>

            <div class="column is-2 pt-0">


              <div class="field">
                <label class="has-text-weight-bold">Invoice Language:</label>
                <div class="control select is-fullwidth  mt-2">
                  <select name='language' :disabled="loaders.creating" v-model="invoice.language">
                    <option value='EN'>English</option>
                    <option value='NL'>Netherlands</option>
                    <option value='PT'>Portuguese</option>
                  </select>

                </div>
              </div>

            </div>

            <div class="column is-2 pt-0">

              <div class="field">
                <label class="has-text-weight-bold">Applicable Taxes (%):</label>
                <div class="control">
                  <input type='number' class='input mt-2' name='taxes' :disabled="loaders.creating" v-model="invoice.taxes">
                </div>
              </div>

            </div>

          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <h3 class="mb-3">
                  <span class="subtitle" v-html="t('Invoice Items', {})">
                  </span>
                </h3>
                <table class="table is-bordered is-fullwidth hide-on-mobile">

                  <thead>
                  <tr>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :key="key" v-for='(item, key) in items'>
                    <td class="v-centered">
                                            <textarea class="textarea is-small" v-model='items[key].description'
                                                      :name='`description[${key}]`'
                                                      rows="2"
                                                      :disabled="loaders.creating"
                                                      style="resize: none"
                                                      :id="key === (items.length - 1) ? `focusDescription` : `description[${key}]`"
                                            ></textarea>
                    </td>
                    <td class="v-centered">
                      <div class="field has-addons is-pulled-right">
                        <div class="control">
                      <input type='number'
                             class='input is-small is-pulled-right'
                             step='0.01'
                             placeholder="0.00"
                             v-model='items[key].quantity'
                             :disabled="loaders.creating"
                             :name='`quantity[${key}]`' style='width: 60px;'>
                          </div>
                      </div>
                    </td>
                    <td class="v-centered">
                      <div class="field has-addons is-pulled-right">
                        <div class="control">
                          <a class="button is-small is-static mr-2">
                            EUR
                          </a>
                        </div>
                        <div class="control">

                      <input type='number' step='.01' class='input is-small'
                             v-model='items[key].price' :name='`price[${key}]`'
                             :disabled="loaders.creating"
                             style='width: 78px;'>

                          </div>
                      </div>
                    </td>
                    <td class="has-text-right v-centered">
                      <small>EUR {{ (items[key].quantity * items[key].price).toFixed(2) }}</small>
                    </td>
                    <td class="has-text-centered v-centered">
                      <button class="button is-small is-danger" @click="removeItem($event, key)"
                              v-if='key < (items.length - 1)'>
                        <strong><span class="fa fa-times"></span> <span>Remove</span></strong>
                      </button>
                      <button class="button is-small is-success" @click="newItem($event)"
                              v-if='key === (items.length - 1)'>
                        <strong><span class="fa fa-check"></span> <span>Add</span></strong>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class='has-text-right'>
                      <small>Sub-total</small>
                    </td>
                    <td class='has-text-right'>
                      <small>EUR {{ subtotal.toFixed(2) }}</small>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="2" class='has-text-right v-centered'>
                      <small>BTW</small>
                    </td>
                    <td class='has-text-centered v-centered'>
                      <div class="field has-addons is-pulled-right">
                        <div class="control">
                          <input type='number'  :disabled="loaders.creating" class='input  is-small mr-2' name='taxes' v-model="invoice.taxes" style="width: 78px;">
                        </div>
                        <div class="control">
                          <a class="button is-small is-static">
                            %
                          </a>
                        </div>
                      </div>
                    </td>
                    <td class='has-text-right v-centered'>
                      <small>EUR {{ (subtotal * (invoice.taxes / 100)).toFixed(2) }}</small>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3" class="has-text-right">
                      <small>Total</small>
                    </td>
                    <td class="has-text-right">
                      <small>EUR {{ (subtotal + (subtotal * (invoice.taxes / 100))).toFixed(2) }}</small>
                    </td>
                    <td></td>
                  </tr>
                  </tbody>

                </table>

                <table class="table is-bordered is-fullwidth display-on-mobile loop-table">
                  <tr :key="key" v-for='(item, key) in items'>
                    <td class="v-centered">
                                            <textarea class="textarea is-small" v-model='items[key].description'
                                                      :name='`description[${key}]`'
                                                      rows="2"
                                                      :disabled="loaders.creating"
                                                      style="resize: none"
                                                      :id="key === (items.length - 1) ? `focusDescription` : `description[${key}]`"
                                            ></textarea>
                    </td>
                    <td class="v-centered">
                      <div class="field has-addons is-pulled-right">
                        <div class="control">
                          <input type='number'
                                 class='input is-small is-pulled-right'
                                 step='0.01'
                                 placeholder="0.00"
                                 v-model='items[key].quantity'
                                 :disabled="loaders.creating"
                                 :name='`quantity[${key}]`' style='width: 60px;'>
                        </div>
                      </div>
                    </td>
                    <td class="v-centered">
                      <div class="field has-addons is-pulled-right">
                        <div class="control">
                          <a class="button is-small is-static mr-2">
                            EUR
                          </a>
                        </div>
                        <div class="control">

                          <input type='number' step='.01' class='input is-small'
                                 v-model='items[key].price' :name='`price[${key}]`'
                                 :disabled="loaders.creating"
                                 style='width: 78px;'>

                        </div>
                      </div>
                    </td>
                    <td class="has-text-right v-centered">
                      <small>EUR {{ (items[key].quantity * items[key].price).toFixed(2) }}</small>
                    </td>
                    <td class="has-text-centered v-centered">
                      <button class="button is-small is-danger" @click="removeItem($event, key)"
                              v-if='key < (items.length - 1)'>
                        <strong><span class="fa fa-times"></span> <span>Remove</span></strong>
                      </button>
                      <button class="button is-small is-success" @click="newItem($event)"
                              v-if='key === (items.length - 1)'>
                        <strong><span class="fa fa-check"></span> <span>Add</span></strong>
                      </button>
                    </td>
                  </tr>
                </table>
                <table class="table is-bordered is-fullwidth display-on-mobile">
                  <tr>
                    <td class='has-text-right'>
                      <small>Sub-total</small>
                    </td>
                    <td class='has-text-right'>
                      <small>EUR {{ subtotal.toFixed(2) }}</small>
                    </td>
                  </tr>
                  <tr>
                    <td class='has-text-right v-centered'>
                      <small>BTW</small>
                    </td>
                    <td class='has-text-centered v-centered'>
                      <div class="field has-addons is-pulled-right">
                        <div class="control">
                          <input type='number'  :disabled="loaders.creating" class='input  is-small mr-2' name='taxes' v-model="invoice.taxes" style="width: 78px;">
                        </div>
                        <div class="control">
                          <a class="button is-small is-static">
                            %
                          </a>
                        </div>
                        <small>EUR {{ (subtotal * (invoice.taxes / 100)).toFixed(2) }}</small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="has-text-right">
                      <small>Total</small>
                    </td>
                    <td class="has-text-right">
                      <small>EUR {{ (subtotal + (subtotal * (invoice.taxes / 100))).toFixed(2) }}</small>
                    </td>
                  </tr>

                </table>

              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <button class="button is-primary has-text-weight-bold is-pulled-right"
                      :disabled="loaders.creating"
                      @click="putInvoiceData">
                <span>Create Invoice</span>
                <span class="icon">
                  <span :class="['fa', {'fa-spin fa-spinner': loaders.creating, 'fa-file-pdf': !loaders.creating}]"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {

  name: 'NewInvoice',
  components: {},
  data() {
    return {
      items: [],
      customer: {},
      loaders: {
        customer: false,
        invoice: false,
        creating: false,
      },
      invoice: {
        invoice: '',
        issued_at: this.$store.getters["Formatting/getTodayFormattedDate"](),
        payment_terms: 14,
        language: 'NL',
        email: '',
        taxes: 21,
      },

      dev: false,
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  computed: {
    subtotal() {
      let self = this;
      let st = 0;
      for (let i in self.items) {
        st += (self.items[i].price * self.items[i].quantity);
      }
      return st;

    }
  },

  created() {
    let self = this;

    self.invoice.payment_terms = self.$route.params.payment_terms;

    this.customer = this.$route.params;



    this.getCustomerData();

    this.getInvoiceData();


    self.addItem();

  },
  methods: {
    ...mapActions([
      "dataviewKit/getViewData",
      "invoiceKit/getNextFormattedInvoiceForCustomer",
      "invoiceKit/putInvoice",
    ]),

    getCustomerData() {
      if(!this.loaders.customer) {
        this.loaders.customer = true;
        let payload =  {
          view: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
          ).identifier === 'ICMS' ? "cm_incaze_nl_all_customers_ICMS" : "cm_incaze_nl_all_customers",
          parameters: {
            owner_identifier: (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier === 'ICMS' && this.$route.params.parent_identifier !== 'ICMS' ?
                'ICMS' : this.$route.params.parent_identifier,
            parent_identifier: false,
          },
          first: true,
          filters: {
            company_id: (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier === 'ICMS' && this.$route.params.parent_identifier !== 'ICMS' ? this.$route.params.parent_identifier : this.$route.params.company_id,
          },
        };
        this["dataviewKit/getViewData"](
            payload,
            { "content-type": "application/json" }
        ).then((data) => {
          this.customer = data.data;
          this.$route.params = { ...this.$route.params, ...data.data };
          this.loaders.customer = false;
        });
      }

    },
    getInvoiceData() {
      if(!this.loaders.invoice) {
        this.loaders.invoice = true;
        this["invoiceKit/getNextFormattedInvoiceForCustomer"](
            {
              owner_supplier: (
                  this.$store.state.me.activeSeller ??
                  this.$store.state.me.user.companies[0] ?? { identifier: "" }
              ).identifier,
            },
            { "content-type": "application/json" }
        ).then((data) => {
          this.invoice.invoice = data.invoice;
          this.loaders.invoice = false;
        });
      }

    },
    putInvoiceData() {
      if(!this.loaders.creating) {
        this.loaders.creating = true;
        this["invoiceKit/putInvoice"](
            Object.assign(this.invoice, {
              owner_supplier: (
                  this.$store.state.me.activeSeller ??
                  this.$store.state.me.user.companies[0] ?? { identifier: "" }
              ).identifier === 'ICMS' && this.$route.params.parent_identifier !== 'ICMS' ?
                  'ICMS' : this.$route.params.parent_identifier,
              owner_buyer: (
                  this.$store.state.me.activeSeller ??
                  this.$store.state.me.user.companies[0] ?? { identifier: "" }
              ).identifier === 'ICMS' && this.$route.params.parent_identifier !== 'ICMS' ? this.$route.params.parent_identifier : this.$route.params.company_id,
              items: this.items,
            }),
            { "content-type": "application/json" }
        ).finally(() => {
          this.loaders.creating = false;

          this.$router.push({
            name: 'Customer',
            params: {
              parent_identifier: this.$route.params.parent_identifier,
              company_id: this.$route.params.company_id,
            }
          })
        });

      }

    },

    checkMobile() {
      this.isMobile = window.innerWidth <= 700 && (
          this.$store.state.me.user.email === 'info@marcocorver.nl'
          || this.$store.state.me.user.email === 'maurice@consultinvest.nl'
          || this.$store.state.me.user.email === 'jim.harders@gmail.com'
      );
    },


    toggleItTools() {
      this.dev=!this.dev;
    },

    newItem($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.addItem();
    },


    removeItem($event, key) {
      let self = this;
      $event.preventDefault();
      $event.stopPropagation();
      self.items.splice(
          key, 1
      );
    },

    addItem() {

      let self = this;

      let focusDesc = document.getElementById('focusDescription');

      if(self.items.length > 0 &&
          self.items[self.items.length - 1].description === '' &&
          Number(self.items[self.items.length - 1].price) === 0) {

        if(focusDesc) { focusDesc.focus(); }

        return false;

      }

      self.items.push({
        description: '',
        quantity: 1,
        price: 0,
      });

      if(focusDesc) { focusDesc.focus(); }

    },

  }
}
</script>